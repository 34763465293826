import Image from "next/legacy/image";
import React, { useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {useTranslation} from 'next-i18next';
import Select from "react-select";
import Link from 'next/link';
import {ShimmerCategoryItem} from "react-shimmer-effects";

// ethereum config
// API helper
import {apiHelperBlockchain} from "../../../helper/apiHelper";
// styles
import styles from "./TopCollection.module.scss";
// components
import dynamic from 'next/dynamic';
// img
import verified from '../../../public/tick.png'
import ethereumImg from '../../../public/img/sorting.png'
import polygonImg from '../../../public/img/polygon.png'
import ethereum from '../../../public/img/eth.svg'

const NotFoundSection = dynamic(() => import('../../../components/NotFoundSection'))

const TopCollection = (props) => {

    const { t } = useTranslation("common");
    const [page_no, setPageNo] = useState(1);
    const pageSize = 9;
    const [collectionList, setCollectionList] = useState(props.hotCollections);
    const [nftLoader, setNftLoader] = useState(false);
    const [clicked_viewMore, setClickedViewMore] = useState(true);
    const [totalCollections, setTotalCollections] = useState(0);
    const [network, setNetwork] = useState({
        value: 1,
        label: <>
            <Image src={ethereumImg} alt={t('common:ethereum')}
                   width={16} height={16}/>
            <span>{t('common:ethereum')}</span>
        </>
    })
    const networkListDropdown = [
        { value: 1, label: <><Image src={ethereumImg} alt={t('common:ethereum')} width={16} height={16} /><span>{t('common:ethereum')}</span></> },
        { value: 2, label: <><Image src={polygonImg} alt={t('common:polygon')} width={16} height={16} /><span>{t('common:polygon')}</span></> },
    ]


    const getAllCollection = () => {

        if (page_no === 1) {
            setNftLoader(true);
        }

        let cList = collectionList;
        let cList1 = [];

        const api = 'get-hot-collections?page=' + page_no + "&size=" + pageSize + "&chainIndex=" + network.value
        apiHelperBlockchain(api, 'GET').then(resp => {
            setNftLoader(false);
            if (resp.data.status) {
                cList1 = resp.data.collections;

                let fArray = cList.concat(cList1);
                setCollectionList(fArray);
                setTotalCollections(resp.data.totalCollections)
            }
        }).catch(error => console.error(`Error: ${error}`));
    }

    useEffect(() => {
        if(totalCollections > 0 ){
            if (collectionList.length === totalCollections) {
                setClickedViewMore(false);
            }
        }
    },[totalCollections])

    useEffect(() => {
        getAllCollection();
    },[page_no,network])

    const loadMoreCollection = () => {
        setPageNo(page_no + 1);
    }

    const handleChangeBlockchain = (opt) => {
        setNetwork(opt);
        setCollectionList([]);
        setPageNo(1);
    };


        let i = 1;
        return (
            <div className={`section ${styles.top_collections}`}>
                <Container>
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                        <h2 className="h3 mb-0">{t('common:all_collections')}</h2>
                        <div className={`${styles.selectMenu} selectMenu`}>
                            <Select
                                inputId="networklist"
                                value={network}
                                onChange={handleChangeBlockchain}
                                options={networkListDropdown}
                                classNamePrefix="select__Inner"
                                placeholder={t('common:select')}
                                isSearchable={false}
                                instanceId="blockchain"
                            />
                        </div>
                    </div>
                    <div className={styles.collection_list}>
                        {nftLoader ?
                            <Row> 
                                <Col xl={4} md={6}>
                                    <ShimmerCategoryItem
                                        hasImage
                                        imageType="circular"
                                        imageWidth={50}
                                        imageHeight={50}
                                        title
                                    />
                                </Col>
                                <Col xl={4} md={6}>
                                    <ShimmerCategoryItem
                                        hasImage
                                        imageType="circular"
                                        imageWidth={50}
                                        imageHeight={50}
                                        title
                                    />
                                </Col>
                                <Col xl={4} md={6}>
                                    <ShimmerCategoryItem
                                        hasImage
                                        imageType="circular"
                                        imageWidth={50}
                                        imageHeight={50}
                                        title
                                    />
                                </Col>
                            </Row>
                            :
                            <Row>
                                {collectionList?.length > 0 ?
                                    collectionList?.map((item, idx) => {
                                        return (
                                            <Col md={6} xl={4} key={idx}>
                                                <Link href={"/nft/collections/" + decodeURI(item.collection_slug)}
                                                      className={`d-flex align-items-center justify-content-between ${styles.collection_list_wrap}`}>
                                                    <div className="d-flex align-items-center">
                                                            <span className={styles.count}>{i++}</span>
                                                            <div className={`position-relative ${styles.card__avatar}`}>
                                                                {item.image_url ?
                                                                    <Image src={item.image_url}
                                                                           alt={t('common:all_collections')}
                                                                           width={50} height={50}
                                                                           layout="responsive"
                                                                           loading='lazy' />
                                                                    : null}
                                                                <div className={styles.done}>
                                                                    <Image src={verified}
                                                                           alt={t('common:all_collections')}
                                                                           width="16" height="16"
                                                                           layout='responsive'
                                                                           loading='lazy' />
                                                                </div>
                                                            </div>
                                                            <div className={styles.card_body}>
                                                                <div className={styles.name}>
                                                                    {item.collection_name?.length > 20 ? item.collection_name.substring(0, 20) + "..." : item.collection_name}</div>
                                                                {item.floorPrice && <div className={`d-flex align-items-center  ${styles.content}`}>
                                                                    <span className={styles.price_lbl}>{t('common:floor_price')}: </span>
                                                                    <Image src={ethereum} alt="Ethereum" width={16} height={16} layout="responsive" />
                                                                    &nbsp; {parseFloat(item.floorPrice).toFixed(4)}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                </Link>
                                            </Col>
                                        )
                                    }) : <NotFoundSection type="collection-outside" />}
                            </Row>
                        }
                    </div>

                    {
                        clicked_viewMore && (page_no === 1 && collectionList.length < pageSize) ?
                            null :
                            <div className="text-center" onClick={loadMoreCollection}>
                                <div className={`btn buttonStroke loadmoreBtn`}>
                                    {t('common:view_more')}
                                </div>
                            </div>
                    }
                </Container >
            </div >
        )

}
export default TopCollection